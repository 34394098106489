<template>
  <div class="home mt-4">
    <AccueilComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import AccueilComponent from '@/components/AccueilComponent.vue';
export default {
  name: 'HomeView',
  components: {
    AccueilComponent
  }
}
</script>

<style>
  
</style>
