<template>
    <div>
        <div class="d-flex justify-content-center">
            <input type="text" v-model="phoneNumber" @input="isFormatVAlid" maxlength="14" placeholder="0" class="mx-2 rounded-pill border-light-subtle"/>
            <button :disabled="!isValid" @click="callPhoneNumber" class="btn btn-primary rounded-pill mx-2">Appeler</button>
        </div>
        <div class="d-flex justify-content-center">
            <div v-if="!isValid" class="error-message">
                Format invalide. Format attendu : 01 10 11 12 13 ou 0110111213
            </div>
            <div v-else class="success-message">
                Format valide.
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AppelComponent',
        data() {
            return {
                phoneNumber: '0',
                isValid: false
            };
        },
        methods: {
            callPhoneNumber() {
                window.location.href = `tel:${this.phoneNumber}`;
            },

            isFormatVAlid() {
                // Vérifiez si le format est valide
                const regex1 = /^(\d{2} ){4}\d{2}$/;
                const regex2 = /^0(\d{9})$/;
                this.isValid = regex1.test(this.phoneNumber) || regex2.test(this.phoneNumber);
            },
        }
    }
</script>

<style>
    .error-message {
        color: red;
        margin-top: 10px;
    }
    .success-message {
        color: rgb(53, 109, 53);
        margin-top: 10px;
    }
</style>