<template>
    <div class="row justify-content-center">
        <div class="col-md-10 p-0">
            <h3>Géolocalisation</h3>
            <button class="btn btn-secondary rounded-pill" @click="getLocation()">Afficher votre localisation</button>
            <p class="mt-2" v-if="latitude && longitude">
                Votre adresse : {{ adresse }}
            </p>
            <div class="mt-2 p-3">
                <div id="map"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import L from 'leaflet';
    //import { ref } from 'vue';
    import axios from 'axios';
    import 'leaflet-control-geocoder'

    export default {
        name: 'GeoComponent',
        data() {
            return {
                latitude: null,
                longitude: null,
                map: null,
                marker: null,
                adresse: ''
            };
        },
        mounted() {
            this.map = L.map('map').setView([51.505, -0.09], 13);
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }).addTo(this.map);

            // Ajouter le contrôle de recherche avec le plugin Leaflet Control Geocoder
            L.Control.geocoder({
                defaultMarkGeocode: false, // Désactiver le marquage automatique des résultats de géocodage sur la carte
                collapsed: false, // Afficher le champ de recherche en permanence
                placeholder: "Rechercher une adresse...", // Texte de l'placeholder du champ de recherche
                geocoder: L.Control.Geocoder.nominatim(), // Utiliser le service de géocodage Nominatim par défaut
            }).on('markgeocode', (e) => { // Événement déclenché lorsqu'un résultat est sélectionné dans la recherche
                const latlng = e.geocode.center;
                this.map.setView(latlng, 13); // Centrer la carte sur le résultat sélectionné
                if (this.marker) {
                    this.marker.setLatLng(latlng);
                } else {
                    this.marker = L.marker(latlng).addTo(this.map);
                }
            }).addTo(this.map); // Ajouter le contrôle à la carte
            },
        methods: {
            getLocation() {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition( position => {
                        this.latitude = position.coords.latitude;
                        this.longitude = position.coords.longitude;
                        this.updateMap();
                        this.getAddressFromCoordinates(this.latitude, this.longitude);
                        this.showGeoNotification(this.latitude, this.longitude);
                    });
                }
            },

            updateMap() {
                if (this.latitude && this.longitude) {
                    const latlng = [this.latitude, this.longitude];
                    if (this.marker) {
                        this.marker.setLatLng(latlng);
                    } else {
                        this.marker = L.marker(latlng).addTo(this.map);
                    }
                    this.marker.bindPopup("Vous êtes ici").openPopup();
                    this.map.setView(latlng, 17);
                }
            },
            showGeoNotification(a,b) {
                if(a && b) {
                    new Notification('Localisation', {
                        body: 'Votre position est : ' + a +' , ' + b,
                        icon: 'https://img.icons8.com/material-outlined/50/000000/camera--v2.png'
                    })
                }
            },

            async getAddressFromCoordinates(lat, lng) {
                try {

                    const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`);

                    if (response.data && response.data.address) {
                        this.adresse = `${response.data.place_rank} ${response.data.address.road}, ${response.data.address.postcode} ${response.data.address.city}, ${response.data.address.country}`;
                    } else {
                        this.adresse = "Adresse introuvable";
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération de l'adresse :", error);
                    this.adresse = "Erreur lors de la récupération de l'adresse";
                }
            }
        }
    }

</script>

<style>
    #map { 
        height: 300px; 
    }
</style>