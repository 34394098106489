<template>
    <div class="mt-3">
        <button @click="toggleBatteryStatus" class="rounded-pill btn-outline-secondary">
            {{ showBatteryInfo ? 'Masquer les informations batterie' : 'Afficher les informations batterie' }}
        </button>
        <div v-if="showBatteryInfo" class="mt-2">
            <div v-if="batteryLevel !== null">
                Niveau de la batterie : <b v-if="batteryLevel > 70" class="success-message">{{ batteryLevel }}</b><b v-else-if="batteryLevel < 21" class="error-message">{{ batteryLevel }}</b><b v-else>{{ batteryLevel }}</b> %
            </div>
            <div v-if="batteryCharging !== null">
                Statut de la charge : {{ batteryCharging ? 'En charge' : 'Pas en charge' }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NiveauBatterie',

        data() {
            return {
                batteryLevel: null,
                batteryCharging: null,
                showBatteryInfo: false
            };
        },

        methods: {
            async getBatteryStatus() {
                try {
                    const battery = await navigator.getBattery();

                    this.batteryLevel = Math.floor(battery.level * 100);
                    this.batteryCharging = battery.charging;

                    battery.addEventListener('levelchange', () => {
                    this.batteryLevel = Math.floor(battery.level * 100);
                    });

                    battery.addEventListener('chargingchange', () => {
                    this.batteryCharging = battery.charging;
                    });
                } catch (error) {
                    console.error('Battery Status API pas supportée', error);
                    alert('Le navigateur ne supporte pas l\'api Battery Status');
                }
            },

            toggleBatteryStatus() {
                this.showBatteryInfo = !this.showBatteryInfo;
                if (this.showBatteryInfo && this.batteryLevel === null) {
                    this.getBatteryStatus();
                }
            }
        }
    }
</script>

<style>
    .error-message {
        color: red;
        margin-top: 10px;
    }
    .success-message {
        color: rgb(53, 109, 53);
        margin-top: 10px;
    }
</style>