<template>
    <div class="mt-3">
        <button @click="toggleVibration" class="rounded-pill btn-outline-secondary">
            {{ isVibrationEnabled ? 'Désactiver le mode vibreur' : 'Activer le mode vibreur' }}
        </button>
        <div class="mt-2">
            Etat : <b>{{ isVibrationEnabled ? 'Activé' : 'Désactivé' }}</b>
        </div>
    </div>
</template>
  
<script>
  export default {
    name: 'ModeVibreur',
    data() {
      return {
        isVibrationEnabled: false
      };
    },
    methods: {
        toggleVibration() {
            this.isVibrationEnabled = !this.isVibrationEnabled;
            if (this.isVibrationEnabled) {
                // Activer le mode vibreur
                navigator.vibrate([200, 100, 200]);
                this.showNotification();
            } else {
                // Désactiver le mode vibreur (arrêter toute vibration en cours)
                navigator.vibrate(0);
            }
        },
        showNotification() {
            new Notification('Vibration', {
                body: 'Vibration activé',
                icon: 'https://img.icons8.com/material-outlined/50/000000/camera--v2.png',
                
            })
        }
    }
  };
</script>
  
<style>
  /* Ajoutez des styles si nécessaire */
</style>