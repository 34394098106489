<template>
  <nav class="nav1">
    <div class="container-fluid d-flex justify-content-between flex-wrap">
      
      <a class="navbar-brand text-white my-auto" href="#">
        <img src="../public/img/images/pwaVueIcone.png" alt="icone pwa vue" class="iconeAccueil">
        &ensp;&ensp;
      </a>
      
      <div class="mt-2 pt-1">
        <span class="dot"></span>
        <span class="dot mx-1"></span>
        <span class="dot"></span>
      </div>
    </div>
  </nav>

  <img alt="Vue logo" src="./assets/logo.png" class="image mt-2 mb-2">

  <div class="container p-0">
    <h3 class="text-center mb-4 mt-2">Progressive Web App</h3>
  </div>

  <nav>
    <router-link to="/" class="link" active-class="active-link">Prendre une photo</router-link> |
    <router-link to="/geo" class="link" active-class="active-link">Consulter une position géographique</router-link> |
    <router-link to="/autre" class="link" active-class="active-link">Autres fonctionnalités</router-link> 
  </nav>

  <router-view/>

</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.link {
  text-decoration: none;
}

.image {
  height: 100px;
}

.nav1 {
  padding: 0px;
  background-color: #42b883;
}

.iconeAccueil {
  width: 100px;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

.active-link {
  color: #b4c6e0;
}
</style>
