<template>
    <div class="row justify-content-center">
        <div class="col-md-7 p-0">
            <div class="card">

                <div class="mt-5">
                    <button type="button" class="btn btn-primary rounded-pill" :class="{ 'btn-primary' : !isCameraOpen, 'btn-danger' : isCameraOpen}" @click="toggleCamera">
                        <span v-if="!isCameraOpen">Ouvrir la Caméra</span>
                        <span v-else>Fermer la Caméra</span>
                    </button>
                </div>

                <div class="d-flex justify-content-center m-3">
                    <div v-show="isCameraOpen && isLoading" class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                
                <div v-if="isCameraOpen" v-show="!isLoading" class="card-body" :class="{ 'flash' : isShotPhoto }">
                    <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
                    
                    <video v-show="!isPhotoTaken" ref="camera" class="w-100 h-auto" autoplay></video>
                    
                    <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" class="w-100 h-auto"></canvas>
                </div>
        
                <div v-if="isCameraOpen && !isLoading" class="d-flex justify-content-center m-3">
                    <button type="button" class="btn" @click="takePhoto">
                        <img src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png">
                    </button>
                </div>
        
                <div v-if="isPhotoTaken && isCameraOpen" class="d-flex justify-content-center m-3">
                    <a id="downloadPhoto" download="my-photo.jpg" class="btn btn-primary rounded-pill" role="button" @click="downloadImage">
                        Télécharger la photo
                    </a>
                </div>

                <div class="d-flex justify-content-center m-3 mb-5">
                    <button type="button" class="btn btn-primary rounded-pill" @click="showTakenPhotos">
                        {{ showPhotos ? 'Masquer les photos prises' : 'Voir les photos prises' }}
                    </button>
                </div>

            </div>

            <div v-if="showPhotos && takenPhotos.length > 0" class="text-center mt-4">
                <p>Liste des photos</p>
            </div>

            <div v-if="showPhotos && takenPhotos.length > 0" class="d-flex flex-wrap justify-content-center">
                <div v-for="(photo, index) in takenPhotos" :key="index" class="position-relative m-2">
                    <img :src="photo" class="img2" alt="Photo">
                    <button class="btn btn-danger position-absolute top-0 end-0" title="Supprimer la photo" @click="deletePhoto(index)">X</button>
                </div>
            </div>

            <div v-else-if="showPhotos" class="text-center mt-4">
                <p>Aucune photo disponible</p>
            </div>
            
        </div>
    </div>
</template>

<script>

    export default {
        name: 'CameraComponent',

        data() {
            return {
                isCameraOpen: false,
                isPhotoTaken: false,
                isShotPhoto: false,
                isLoading: false,
                showPhotos: false,
                takenPhotos: [],
                maxPhotos: 8,
            }
        },

        methods: {

            toggleCamera() {
                if(this.isCameraOpen) {
                    this.isCameraOpen = false;
                    this.isPhotoTaken = false;
                    this.isShotPhoto = false;
                    this.stopCameraStream();
                } else {
                    this.isCameraOpen = true;
                    this.createCameraElement();
                }
            },
    
            createCameraElement() {
                this.isLoading = true;
            
                const constraints = (window.constraints = {
                    audio: false,
                    video: true
                });

                navigator.mediaDevices.getUserMedia(constraints).then(stream => {
                    this.isLoading = false;
                    this.$refs.camera.srcObject = stream;
                }).catch(() => {
                    this.isLoading = false;
                    alert("Erreuur---------------------");
                });
            },
    
            stopCameraStream() {
                let tracks = this.$refs.camera.srcObject.getTracks();

                tracks.forEach(track => {
                    track.stop();
                });
            },
    
            takePhoto() {
                const storedPhotos = JSON.parse(localStorage.getItem('photos')) || [];

                if (storedPhotos.length >= this.maxPhotos) {
                    this.showNotification2();
                } else {
                    if(!this.isPhotoTaken) {
                        this.isShotPhoto = true;

                        const FLASH_TIMEOUT = 50;

                        setTimeout(() => {
                            this.isShotPhoto = false;
                        }, FLASH_TIMEOUT);
                    }
        
                    this.isPhotoTaken = !this.isPhotoTaken;

                    const videoElement = this.$refs.camera;
                    const canvasElement = this.$refs.canvas;

                    // dimensions
                    canvasElement.width = videoElement.videoWidth;
                    canvasElement.height = videoElement.videoHeight;
        
                    const context = this.$refs.canvas.getContext('2d');
                    context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

                    // Convertir l'image en base64
                    const imageData = canvasElement.toDataURL('image/png');

                    if (navigator.onLine) {
                        // Enregistrer l'image dans le localStorage
                        const photos = JSON.parse(localStorage.getItem('photos')) || [];
                        photos.push(imageData);
                        localStorage.setItem('photos', JSON.stringify(photos));
                    } else {
                        // Envoyer l'image au service worker pour la mettre en cache
                        navigator.serviceWorker.controller.postMessage({
                            type: 'SAVE_PHOTO',
                            photo: imageData
                        });
                    }

                    // Envoi de la notification lorsque la photo est prise
                    this.sendNotification();
                }
            },

            showTakenPhotos() {
                this.showPhotos = !this.showPhotos; // Toggle l'affichage des photos
                if (this.showPhotos) {
                    // Charger les photos depuis le localStorage
                    this.takenPhotos = JSON.parse(localStorage.getItem('photos')) || [];
                } else {
                    // Cacher les photos
                    this.takenPhotos = [];
                }
            },
    
            downloadImage() {
                const download = document.getElementById("downloadPhoto");
                const canvas = document.getElementById("photoTaken").toDataURL("image/png").replace("image/png", "image/octet-stream");
                download.setAttribute("href", canvas);
            },

            deletePhoto(index) {
                this.takenPhotos.splice(index, 1);
                localStorage.setItem('photos', JSON.stringify(this.takenPhotos));
                this.showDeleteNotification();
            },

            sendNotification() {
                // console.log("Vérification de l'autorisation de notification...");
                if (!('Notification' in window)) {
                    console.log("Ce navigateur ne prend pas en charge les notifications sur le bureau");
                } else if(Notification.permission == 'granted') {
                    // console.log("Permission granted, showing notification...");
                    this.showNotification();
                } else if(Notification.permission == 'denied') {
                    // console.log("Demander l'autorisation pour les notifications...");
                    Notification.requestPermission().then(permission => {
                        if(permission == 'granted') {
                            console.log("Autorisation accordée...");
                            this.showNotification();
                        }
                    })
                } else {
                    console.log("Autorisation refusée pour les notifications");
                }
            },

            showNotification() {
                // console.log("Affichage des notifications...");
                new Notification('Photo prise', {
                    body: 'Votre photo a été prise avec succès!',
                    icon: 'https://img.icons8.com/material-outlined/50/000000/camera--v2.png'
                })
            },

            showNotification2() {
                new Notification('Stockage plein', {
                    body: 'Le stockage de photos est plein.',
                    icon: '../../public/img/icons/warning_icone.png'
                })
            },

            showDeleteNotification() {
                new Notification('Photo supprimée', {
                    body: 'Votre photo a été supprimée avec succès!',
                    icon: 'https://img.icons8.com/material-outlined/50/000000/trash.png'
                });
            },
        }
    }

</script>

<style>
    .img2 {
        max-width: 150px;
        max-height: 150px;
    }
</style>