<template>
    <div class="container p-0">
        <CameraComponent />
    </div>
</template>
  
<script>
    import CameraComponent from '../components/CameraComponent.vue'
    export default {
        name: 'AccueilComponent',
        components: {
            CameraComponent
        }
    }
</script>
  
<style>
    .paragraphe {
        font-size: 0.7rem;
    }

    .barre {
        background-color: #42b883;
    }
</style>
  