<template>
    <div class="home mt-4">
      <GeoComponent />
    </div>
</template>
  
<script>
  // @ is an alias to /src
  import GeoComponent from '@/components/GeoComponent.vue';
  export default {
    name: 'HomeView',
    components: {
      GeoComponent
    }
  }
</script>
  
<style>

</style>