<template>
  <div class="container mt-4">
    <h5 class="mb-3 text-white rounded px-2" style="text-align: justify; background-color: #42b883;"><b>Passer un appel téléphonique</b></h5>

    <AppelComponent />

    <h5 class="mb-3 text-white rounded mt-3 px-2" style="text-align: justify; background-color: #42b883;"><b>Informations concernant la batterie</b></h5>
    <NiveauBatterie />

    <h5 class="mb-3 text-white rounded mt-3 px-2" style="text-align: justify; background-color: #42b883;"><b>Vibration</b></h5>
    <ModeVibreur />
  </div>
</template>

<script>
  // @ is an alias to /src
  import AppelComponent from '@/components/AppelComponent.vue';
  import NiveauBatterie from '@/components/NiveauBatterie.vue';
  import ModeVibreur from '@/components/ModeVibreur.vue';

  export default {
    name: 'HomeView',
    components: {
      AppelComponent,
      NiveauBatterie,
      ModeVibreur
    }
  }
</script>
  
<style>

</style>
