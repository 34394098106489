import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'

import '@fortawesome/fontawesome-free/js/all';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

createApp(App).use(router).mount('#app')


function requestNotificationPermission() {
    if ('Notification' in window && Notification.permission !== 'granted') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
        } else {
            console.log('Notification permission denied.');
        }
      });
    }
}
  
requestNotificationPermission();

window.addEventListener('online', () => {
    sendMessageToSW({ type: 'ONLINE' });
});
  
window.addEventListener('offline', () => {
    sendMessageToSW({ type: 'OFFLINE' });
});
  
function sendMessageToSW(message) {
    if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage(message);
    }
}

if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('message', event => {
        const message = event.data;

        if (message.type === 'SYNC_PHOTO') {
            const storedPhotos = JSON.parse(localStorage.getItem('photos')) || [];
            storedPhotos.push(message.photo);
            localStorage.setItem('photos', JSON.stringify(storedPhotos));
        }
    });
}